import Head from 'next/head';
import { useEffect } from 'react';
import SignIn from './auth/sign-in';

const Home = () => {
  useEffect(() => {
    const wa_btnSetting = {
      btnColor: '#16BE45',
      ctaText: 'WhatsApp Us',
      cornerRadius: 40,
      marginBottom: 20,
      marginLeft: 20,
      marginRight: 20,
      btnPosition: 'right',
      whatsAppNumber: '2348132574615',
      welcomeMessage: 'My name is: \n',
      zIndex: 999999,
      btnColorScheme: 'light',
    };
  }, []);

  return (
    <>
      <Head>
        <title>Stakecut | Signin</title>
        <meta
          name="description"
          content="A Simple Affiliate Cart System That Allows You: Take Affiliate Orders in Dollars & Major African Currencies By Simply Copying & Pasting a Single Line of Code on Your Website"
        />

        <link rel="icon" href="/favicon.png" />
        <script
          async
          src="https://d2mpatx37cqexb.cloudfront.net/delightchat-whatsapp-widget/embeds/embed.min.js"
        ></script>
      </Head>
      

      <SignIn />
    </>
  );
};

export default Home;

export async function getServerSideProps(context) {
  const { req, res } = context;
  const token = req.cookies.authToken;
  if (token) {
    // Redirect to dashboard if token exists
    return {
      redirect: {
        destination: '/dashboard/affiliate',
        statusCode: 302,
      },
    };
  }
  return {
    props: {}, // will be passed to the page component as props
  };
}
